import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSpring, animated, useTrail } from 'react-spring'
import React, { useState } from 'react'
import '../Styles/Menu.css'
import '../Styles/menuButton.css'


const Menu = ({ page, updatePage }) => {

    const [isOpen, setIsOpen] = useState(false)

    const items = [
        {
            label: 'Acceuil', 
            icon: 'home',
            page: 'Home'
        },
        {
            label: 'Expérience', 
            icon: 'briefcase',
            page: 'Experience'
        },
        {
            label: 'Projets', 
            icon: 'lightbulb',
            page: 'Projects'
        },
        {
            label: 'Contact',
            icon: 'at',
            page: 'Contact'
        }
    ]
    const configOpen = { mass: 5, tension: 500, friction: 200 }
    const configClose = { mass: 5, tension: 6000, friction: 200 }

    const openMenu = useSpring({
        width: isOpen ? 300 : 75
    })

    const trail = useTrail(items.length, {
        config: isOpen ? configOpen : configClose,
        opacity: isOpen ? 1 : 0,
        width: isOpen ? '100%' : '0%',
        from: { opacity: 0, width: '0%' }
    })

    const changePage = (page) => {
        updatePage(page)
        setIsOpen(false)
    }

    return (
        <animated.div style={openMenu} className='menuContainer' >
            <div>
                <div className={isOpen ? 'open' : null} onClick={() => setIsOpen(!isOpen)} id='nav-icon'>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <ul>
                {
                    trail.map(({ opacity, width }, index) => {
                        return (
                            <li 
                                key={index} 
                                style={{cursor: 'pointer'}} 
                                className={page === items[index].page ? 'selected' : null} 
                                onClick={() => changePage(items[index].page)} 
                            >
                                <b></b>
                                <b></b>
                                <div className='icon' >
                                    <FontAwesomeIcon icon={items[index].icon}/>
                                </div>
                                <animated.div style={{ opacity, width }} className='label' >{items[index].label}</animated.div>
                            </li>
                        )
                    })
                }
                {/*
                <li>
                    <div className='icon' >
                        <FontAwesomeIcon icon='home'/>
                    </div>
                    <animated.div style={labelAppear} className='label' >Acceuil</animated.div>
                </li>
                <li>
                    <div className='icon' >
                        <FontAwesomeIcon icon='briefcase'/>
                    </div>
                    <div className='label' >{ isOpen ? 'Expérience' : null} </div>
                </li>
                <li>
                    <div className='icon' >
                        <FontAwesomeIcon icon='lightbulb'/>
                    </div>
                    <div className='label' >{ isOpen ? 'Projets' : null} </div>
                </li>
                <li>
                    <div className='icon' >
                        <FontAwesomeIcon icon='at'/>
                    </div>
                    <div className='label' >{ isOpen ? 'Contact' : null} </div>
                </li>
                */}
            </ul>
        </animated.div>
    )
}

export default Menu
