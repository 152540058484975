import React from 'react'
import '../Styles/Experience.css'

const Experience = () => {

    const Card = ({ company, job, children, date }) => {
        return (
            <div className='card' >
                <div className='header'><strong>{company}</strong>{job ? ' - ' + job : null}</div>
                <div className='card-body'>
                    {children}
                </div>
                <div className='footer'>{date}</div>
            </div>
        )
    }

    return (
        <div className='experienceContainer' >
            <div>
                <h1>Emplois</h1>
                <div>
                    <Card company='Orange' job='Développeur PHP' date='2019 - 2020' >
                        <ul className='list'>
                            <li>Fix de bug d'un ERP.</li>
                            <li>Comparaison de solution SGBD pour une migration de la base de données de l'ERP.</li>
                            <li>Migration de la base de données de l'ERP.</li>
                            <li>Mise en place d'un workflow GIT.</li>
                            <li>Fait en PHP 5, HTML, CSS, JS. Avec CodeIgniter, DHTMLX, et MariaDB.</li>
                            <li>Puis, fait en PHP 7, HTML, CSS, JS. Avec Laravel, DHTMLX, et PostgreSQL.</li>
                        </ul>
                    </Card>
                    <Card company='Kem One' job='Opérateur services généreaux' date='2017 et 2018' >
                        <ul className='list'>
                            <li>Travail d'été en poste 5x8.</li>
                            <li>Nettoyage des machines de production.</li>
                            <li>Dosage des ingrédients.</li>
                        </ul>
                    </Card>
                </div>
            </div>
            <div>
                <h1>Stage</h1>
                <div>
                    <Card company='Fumetsu no Yume' job='Développeur PHP' date='2019' >
                        <ul className='list'>
                            <li>Conception et développement d'un site vitrine et d'une interface administrateur.</li>
                            <li>Mise en production de ces derniers.</li>
                            <li>Maintenance en bénévolat jusqu'au 27 juillet 2021.</li>
                            <li>Fait en PHP. Avec Symfony 4, et Bootstrap 4.</li>
                        </ul>
                    </Card>
                    <Card company='CARSAT Rhône Aples' job='Développeur C#' date='2018' >
                        <p>Développement d'une application web de gestion de formation.<br/>Fait en C#, ASP.Net, EntityFramework.</p>
                    </Card>
                </div>
            </div>
            <div>
                <h1>Études</h1>
                <div>
                    <Card company="IPI Lyon, Lyon" date='2019 - 2020'  >
                        Concepteur Développeur d'Application Numérique, formation suivie
                    </Card>
                    <Card company="Lycée Jacques Brel, Vénissieux" date='2017 - 2019'  >
                        BTS Services Informatiques aux Organisations Option SLAM
                    </Card>
                    <Card company="Université Claude Bernard, Villeurbanne" date='2015 - 2017'  >
                        Licence Maths-Info
                    </Card>
                    <Card company="Lycée Léonard de Vinci, Villefontaine" date='2015'  >
                        Baccalauréat Scientifique option Science de l'Ingénieur
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Experience
