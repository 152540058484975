import React from 'react'
import '../Styles/Project.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-bootstrap'
import '../../Helpers/bootstrap.css'

const Project = ({ project, updateCurrentProject }) => {
    return (
        <div className='projectContainer'>
            <nav>
                <button className='backButton' onClick={() => updateCurrentProject('projects')} ><FontAwesomeIcon icon='arrow-left' /> Projets </button>
            </nav>
            <div className='project-title'>
                <h1>{project.name}</h1>
            </div>
            <div className='project-content' >
                <div className='desktop-images'>
                    <Carousel nextLabel='' prevLabel='' fade variant='dark' >
                        {
                            project.images.map((elem, index) => {
                                return (
                                    <Carousel.Item key={`image-${index}`} className={project.category !== 'mobile' ? 'project-image' : 'mobile project-image'} >
                                        <img src={elem.image} alt='480' />
                                        {
                                            elem.caption ?
                                                (
                                                    <Carousel.Caption className='project-image-caption' >
                                                        <h4>{elem.caption.title}</h4>
                                                        {elem.caption.content ? <p>{elem.caption.content}</p> : null}
                                                    </Carousel.Caption>
                                                ) : null
                                        }
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className='desktop-info'>
                    <div>
                        <div className='project-summary'>
                            <h2>Description</h2>
                            <span>{project.description}</span>
                        </div>
                        <div className='project-tech'>
                            <h3>Technologies</h3>
                            <span>
                                <div>
                                    <span>Language:</span>
                                    <span>{project.tech.language}</span>
                                </div>
                                <div>
                                    <span>Framework:</span>
                                    <span>{project.tech.framework}</span>
                                </div>
                            </span>
                        </div>
                    </div>
                    {
                        project.links.length !== 0 ?
                            (
                                <div className='project-links'>
                                    <h3>Liens</h3>
                                    {
                                        project.links.map(elem => {
                                            return elem
                                        })
                                    }
                                </div>
                            )
                        :
                            (
                                <div className='project-links'></div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default Project
