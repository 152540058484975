import React, { useState } from 'react'
import '../Styles/Menu.css'
import '../Styles/menuButton.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSpring, animated } from 'react-spring'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, fab)

const Menu = ({ page, updatePage }) => {

    const [isOpen, setIsOpen] = useState(false)

    const props = useSpring({
        paddingTop: 100,
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? `translateX(-0%)` : `translate(-100%)`,
        /*from: { left: isOpen ? '-60%' : '0', opacity: isOpen ? 0 : 1, paddingTop: 90 },
        to: { left: isOpen ? '0' : '-60%', opacity: isOpen ? 1 : 0},*/
    })
    const overlay = useSpring({
        immediate: true,
        width: isOpen ? '100%' : '0%',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
    })
    const overlayOpacity = useSpring({
        opacity: isOpen ? 1 : 0,
        backgroundColor: '#0008',
        height: '100%',
        width: '100%',
    })

    const span = useSpring({
        backgroundColor: isOpen ? '#ffffff' : '#93000e',
        border: isOpen ? 'none transparent 0px' : 'solid #282828 2px',
        immediate: true
    })
    const home = useSpring({
        backgroundColor: page === 'Home' ? 'white' : 'transparent',
        color: page === 'Home' ? 'black' : 'white', 
        borderRadius: 15,
    })
    const experience = useSpring({
        backgroundColor: page === 'Experience' ? 'white' : 'transparent',
        color: page === 'Experience' ? 'black' : 'white', 
        borderRadius: 15,
    })
    const projects = useSpring({
        backgroundColor: page === 'Projects' ? 'white' : 'transparent',
        color: page === 'Projects' ? 'black' : 'white', 
        borderRadius: 15,
    })
    const contact = useSpring({
        backgroundColor: page === 'Contact' ? 'white' : 'transparent',
        color: page === 'Contact' ? 'black' : 'white', 
        borderRadius: 15,
    })

    const changePage = (page) => {
        updatePage(page)
        setIsOpen(false)
    }
    const showMenu = (e, show) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(show)
    }

    return (
        <div>
            <div className='menuButton'>
                <div className={isOpen ? 'open' : null} onClick={(e) => showMenu(e, !isOpen)} id='nav-icon'>
                    <animated.span style={span}></animated.span>
                    <animated.span style={span}></animated.span>
                    <animated.span style={span}></animated.span>
                </div>
            </div>
            <animated.nav style={props} className='menu' >
                <ul>
                    <li>
                        <animated.button style={home} onClick={() => changePage('Home')}>
                            <FontAwesomeIcon style={{fontSize: 24}} icon='home' />
                            &emsp;Acceuil
                        </animated.button>
                    </li>
                    <li>
                        <animated.button style={experience} onClick={() => changePage('Experience')}>
                            <FontAwesomeIcon style={{fontSize: 24}} icon='briefcase' />
                            &emsp;Expériences
                        </animated.button>
                    </li>
                    <li>
                        <animated.button style={projects} onClick={() => changePage('Projects')}>
                            <FontAwesomeIcon style={{fontSize: 24}} icon='lightbulb' />
                            &emsp;Projets
                        </animated.button>
                    </li>
                    <li>
                        <animated.button style={contact} onClick={() => changePage('Contact')}>
                            <FontAwesomeIcon style={{fontSize: 24}} icon='at' />
                            &emsp;Contact
                        </animated.button>
                    </li>
                </ul>
            </animated.nav>
            <animated.div onClick={(e) => showMenu(e, false)} style={overlay}>
                <animated.div style={overlayOpacity} ></animated.div>
            </animated.div>
        </div>
    )
}

export default Menu
