import React, { useState } from 'react'
import projects from '../../Data/Projects'
import Accordion from './Helpers/Accordion'
import AccordionItem from './Helpers/AccordionItem'
import Project from './Project'

import '../Styles/Accordion.css'
import '../Styles/Projects.css'

const Projects = () => {

    const [currentProject, setCurrentProject] = useState('projects')

    let softwareProjects = []
    let webProjects = []
    let mobileProjects = []
    let otherProjects = []

    projects.forEach(elem => {
        switch(elem.category){
            case 'software':
                softwareProjects.push(elem)
                break;
            case 'web':
                webProjects.push(elem)
                break;
            case 'mobile':
                mobileProjects.push(elem)
                break;
            default:
                otherProjects.push(elem)
        }
    })

    const displayProjectPage = () => {
        if(currentProject === 'projects') { return <ProjectsList /> }
        else { return <Project setCurrentProject={setCurrentProject} project={projects.find(elem => elem.nickname === currentProject)} /> }
    }

    const ProjectsList = () => {
        return (
            <div id='projects' >
                <Accordion className='projects-accordion' atomic={true} >
                    <AccordionItem title='Logiciel' >
                        <div className='section'>
                            {
                                softwareProjects.map((elem, index) => {
                                    return (
                                        <div onClick={() => setCurrentProject(elem.nickname)} key={elem.id} className='project'>
                                            <img src={elem.icon} alt={elem.name}/><br/>
                                            <span>{elem.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </AccordionItem>
                    <AccordionItem title='Web' >
                        <div className='section'>
                            {
                                webProjects.map((elem, index) => {
                                    return (
                                        <div onClick={() => setCurrentProject(elem.nickname)} key={elem.id} className='project'>
                                            <img src={elem.icon} alt={elem.name}/><br/>
                                            <span>{elem.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </AccordionItem>
                    <AccordionItem title='Mobile' >
                        <div className='section'>
                            {
                                mobileProjects.map((elem, index) => {
                                    return (
                                        <div onClick={() => setCurrentProject(elem.nickname)} key={elem.id} className='project'>
                                            <img src={elem.icon} alt={elem.name}/><br/>
                                            <span>{elem.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </AccordionItem>
                    <AccordionItem title='Autres' >
                        <div className='section'>
                            {
                                otherProjects.map((elem, index) => {
                                    return (
                                        <div onClick={() => setCurrentProject(elem.nickname)} key={elem.id} className='project'>
                                            <img src={elem.icon} alt={elem.name}/><br/>
                                            <span>{elem.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        )
    }

    return (
        <div className='projects-mainContainer'>
                {displayProjectPage()}
        </div>
    )
}

export default Projects
