import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../Styles/Home.css'
import me from '../../Assets/Images/me.png'
import cv from '../../Assets/File/CV.pdf'

const Home = ({ updatePage }) => {
    return (
        <div className='homeContainer' >
            <div className='desktop-meImage' >
                    <img src={me} alt='Moi' />
            </div>
            <div className='desktop-info'>
                <div className='desktop-details'>
                    <table cellSpacing='0' cellPadding='0' >
                        <tbody>
                            <tr className='odd'>
                                <td><FontAwesomeIcon icon='map-marker-alt' /></td>
                                <td>Adresse</td>
                                <td className='detailsInfo'>12 Rue Phillipe Goy, 69500, BRON</td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon='phone' /></td>
                                <td>Téléphone</td>
                                <td className='detailsInfo'>06.20.23.56.61</td>
                            </tr>
                            <tr className='odd'>
                                <td><FontAwesomeIcon icon='at' /></td>
                                <td>Email</td>
                                <td className='detailsInfo'>loic.rojon@gmail.com</td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon='file-alt' /></td>
                                <td>CV</td>
                                <td className='detailsInfo'><a target='_blank' rel='noreferrer' href={cv} >PDF</a></td>
                            </tr>
                            <tr className='odd'>
                                <td><FontAwesomeIcon icon={['fab', 'github']} /></td>
                                <td>Github</td>
                                <td className='detailsInfo'><a target='_blank' rel='noreferrer' href='https://github.com/LRojon' >LRojon</a></td>
                            </tr>
                            <tr>
                                <td><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></td>
                                <td>LinkedIn</td>
                                <td className='detailsInfo'><a target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/lo%C3%AFc-rojon-89a752180/' >Loïc Rojon</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='desktop-linkGroup'>
                    <button onClick={() => updatePage('Experience')} >Expériences</button>
                    <button onClick={() => updatePage('Projects')} >Projets</button>
                    <button onClick={() => updatePage('Contact')} >Contact</button>
                </div>
            </div>
        </div>
    )
}

export default Home
