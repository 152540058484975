import React, { useState } from 'react'
import '../Styles/Projects.css'
import projects from '../../Data/Projects'
import Project from './Project'

const Projects = () => {

    const [currentProject, updateCurrentProject] = useState('projects')

    let softwareProjects = []
    let webProjects = []
    let mobileProjects = []
    let otherProjects = []

    projects.forEach(elem => {
        switch(elem.category){
            case 'software':
                softwareProjects.push(elem)
                break;
            case 'web':
                webProjects.push(elem)
                break;
            case 'mobile':
                mobileProjects.push(elem)
                break;
            default:
                otherProjects.push(elem)
        }
    })

    const displayProject = () => {
        if(currentProject === 'projects') { return <ProjectsHome /> }
        else { return <Project updateCurrentProject={updateCurrentProject} project={projects.find(elem => elem.nickname === currentProject)} /> }
    }

    const ProjectsHome = () => {
        return (
            <div className='projectsContainer' >
                <div>
                    <div className='projectCategory' >
                        <h1>Projets logiciel</h1>
                        <div>
                            {
                                softwareProjects.map(project => {
                                    return (
                                        <div onClick={() => updateCurrentProject(project.nickname)} className='projectLogo' key={project.id} >
                                            <img src={project.icon} alt={project.name} />
                                            <span>{project.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='projectCategory' >
                        <h1>Projets web</h1>
                        <div>
                            {
                                webProjects.map(project => {
                                    return (
                                        <div onClick={() => updateCurrentProject(project.nickname)} className='projectLogo' key={project.id} >
                                            <img src={project.icon} alt={project.name} />
                                            <span>{project.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div className='projectCategory' >
                        <h1>Projets mobile</h1>
                        <div>
                            {
                                mobileProjects.map(project => {
                                    return (
                                        <div onClick={() => updateCurrentProject(project.nickname)} className='projectLogo' key={project.id} >
                                            <img src={project.icon} alt={project.name} />
                                            <span>{project.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='projectCategory' >
                        <h1>Autres projet</h1>
                        <div>
                            {
                                otherProjects.map(project => {
                                    return (
                                        <div onClick={() => updateCurrentProject(project.nickname)} className='projectLogo' key={project.id} >
                                            <img src={project.icon} alt={project.name} />
                                            <span>{project.name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{flex: 1}} >
            {
                displayProject()
            }
        </div>
    )
}

export default Projects
