import React, { useState } from 'react'
import { init, send } from 'emailjs-com'
import { useSpring, animated } from 'react-spring'

import '../Styles/Contact.css'
import emailInfo from '../../Helpers/emailJSKey'

const Contact = () => {

    const [mail, setMail] = useState('')
    const [body, setBody] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const [isOK, setIsOK] = useState(true)
    init(emailInfo.USER_ID)

    const visible = useSpring({
        opacity: isVisible ? 1 : 0
    })

    const sendMail = () => {
        send(emailInfo.GMAIL, 
            emailInfo.TEMPLATE_ID, 
            {
                mail: mail,
                message: body
            }
        ).then((res) => {
            setBody('')
            setMail('')
            if(res.status === 200) { setIsOK(true) }
            else { setIsOK(false) }
            setIsVisible(true)
            setTimeout(() => {
                setIsVisible(false)
            }, 2500);
        })
    }

    return (
        <div className='contact-mainContainer'>
            <animated.div style={visible} className={`alert ${isOK ? 'ok' : 'notOk'}`}>
                {isOK ? 'Le mail a été envoyé.' : 'Un problème est survenue.'}
            </animated.div>
            <div className='mail'>
                <input onChange={(e) => setMail(e.target.value)} type='email' value={mail} placeholder='votre.email@mail.com'/>
            </div>
            <div className='body'>
                <textarea onChange={(e) => setBody(e.target.value)} placeholder='Votre message' value={body}></textarea><br/>
            </div>
            <div className='send'>
                <button onClick={() => sendMail()} >Envoyer</button>
            </div>
        </div>
    )
}

export default Contact
