import { React, useState } from "react";
import './Collapse.css'

const Collapse = ({ collapsed, title, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    return (
        <div className='collapse'>
            <button
                className={isCollapsed ? "collapse-button isCollapse" : "collapse-button" }
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                {title}
            </button>
            <div
                className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`}
                aria-expanded={isCollapsed}
            >
                {children}
            </div>
        </div>
    );
};

export default Collapse