import genMap_file from '../Assets/File/GenPerlin.zip'
import genMap_genMap from '../Assets/Images/genMap/genMap.png'
import genMap_genLaby from '../Assets/Images/genMap/genLaby.png'

import planing1 from '../Assets/Images/planing/planing1.png'
import planing2 from '../Assets/Images/planing/planing2.png'
import planing3 from '../Assets/Images/planing/planing3.png'

import dungeon_interface from '../Assets/Images/dungeon/interface.png'
import dungeon_map from '../Assets/Images/dungeon/Map.png'
import dungeon_inGame from '../Assets/Images/dungeon/inGame.png'

import albion_search from '../Assets/Images/albion/search.png'
import albion_result1 from '../Assets/Images/albion/result1.png'
import albion_result2 from '../Assets/Images/albion/result2.png'

import bestiary_home from '../Assets/Images/bestiaire/creature.PNG'
import bestiary_loot from '../Assets/Images/bestiaire/loot.PNG'
import bestiary_skill from '../Assets/Images/bestiaire/skill.PNG'
import bestiary_abilities from '../Assets/Images/bestiaire/abilities.PNG'
import bestiary_new from '../Assets/Images/bestiaire/new.PNG'

import cardmaker_home from '../Assets/Images/cardmaker/Cardmaker.png'
import cardmaker_add from '../Assets/Images/cardmaker/CardmakerAdd.png'
import cardmaker_modify from '../Assets/Images/cardmaker/CardmakerModify.png'

import worldGen_home from '../Assets/Images/worldgen/Carte_du_monde.png'
import worldGen_kingdom from '../Assets/Images/worldgen/Info_Nation.png'
import worldGen_City from '../Assets/Images/worldgen/Info_Ville.png'
import worldGen_Gods from '../Assets/Images/worldgen/Info_Dieux.png'

import fny_home from '../Assets/Images/fny/acceuil.png'
import fny_newA from '../Assets/Images/fny/Article.png'
import fny_newE from '../Assets/Images/fny/event.png'
import fny_inscription from '../Assets/Images/fny/inscription.png'
import fny_task from '../Assets/Images/fny/tache.png'
import fny_report from '../Assets/Images/fny/account.png'

import planingV2_5_10 from '../Assets/Images/planing2/planing5-10.jpg'
import planingV2_3_10 from '../Assets/Images/planing2/planing3-10.jpg'
import planingV2_3_23 from '../Assets/Images/planing2/planing3-23.jpg'

import genshinOverview_menu from '../Assets/Images/genshinOverview/menu.png'
import genshinOverview_userInfo from '../Assets/Images/genshinOverview/userInfo.png'
import genshinOverview_pCharacter from '../Assets/Images/genshinOverview/persoCharacter.png'
import genshinOverview_pCharacterDetails from '../Assets/Images/genshinOverview/persoCharacterDetails.png'
import genshinOverview_pSpiral from '../Assets/Images/genshinOverview/persoSpiral.png'
import genshinOverview_dDaily from '../Assets/Images/genshinOverview/dbDaily.png'
import genshinOverview_dCharacter from '../Assets/Images/genshinOverview/dbCharacter.png'
import genshinOverview_dCharacterDetails from '../Assets/Images/genshinOverview/dbCharacterDetails.png'
import genshinOverview_dWeapon from '../Assets/Images/genshinOverview/dbWeapon.png'
import genshinOverview_dWeaponDetails from '../Assets/Images/genshinOverview/dbWeaponDetails.png'
import genshinOverview_dArtifact from '../Assets/Images/genshinOverview/dbArtifact.png'
import genshinOverview_dArtifactDetails from '../Assets/Images/genshinOverview/dbArtifactDetails.png'
import genshinOverview_dElement from '../Assets/Images/genshinOverview/dbElement.png'
import genshinOverview_dElementDetails from '../Assets/Images/genshinOverview/dbElementDetails.png'
import genshinOverview_dFilter from '../Assets/Images/genshinOverview/dbFilter.png'
import voronoiWallpaper_home from '../Assets//Images/voronoiWallpaper/home.png'
import voronoiWallpaper_color from '../Assets//Images/voronoiWallpaper/color.png'
import voronoiWallpaper_menu from '../Assets//Images/voronoiWallpaper/menu.png'
import voronoiWallpaper_gradient from '../Assets//Images/voronoiWallpaper/gradient.png'
import voronoiWallpaper_inverted from '../Assets//Images/voronoiWallpaper/inverted.png'
import voronoiWallpaper_gradient_inverted from '../Assets//Images/voronoiWallpaper/gradient_inverted.png'

import genMap from '../Assets/Images/Icon/map.ico'
import albion from '../Assets/Images/Icon/albion.ico'
import bestiary from '../Assets/Images/Icon/bestiaire.ico'
import cardmaker from '../Assets/Images/Icon/cardmaker.png'
import dungeon from '../Assets/Images/Icon/dungeon.ico'
import planing from '../Assets/Images/Icon/planing.ico'
import worldGen from '../Assets/Images/Icon/worldgen.png'
import fny from '../Assets/Images/Icon/fny.png'
import planingV2 from '../Assets/Images/Icon/planing2.ico'
import genshinOverview from '../Assets/Images/Icon/genshinOverview.png'
import voronoiWallpaper from '../Assets/Images/Icon/voronoiWallpaper.ico'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*
const exemple = [
    {
        id: Number,
        name: String,
        nickname: String,
        icon: Image,
        category: String,
        summary: String,
        description: String,
        tech: {
            language: String,
            framework: String
        },
        links: [
            Component
        ],
        images: [
            {
                caption? : {
                    title: String,
                    content?: String
                },
                image: Image
            }
        ]
    }
]
*/

const projects = [
    { // GenMap
        id: 1,
        name: 'Générateur de cartes',
        nickname: 'GenMap',
        icon: genMap,
        category: 'software',
        summary: <div>Ce logiciel permet de générer une carte de monde selon certains paramètres.<br/>Il permet aussi de générer un labyrinthe.</div>,
        description: <div>&emsp;Ce logiciel permet de générer une carte de monde selon certains paramètres.<br/>Il permet aussi de générer un labyrinthe.<br/><br/>&emsp;Les cartes de monde sont générées à partir du bruit de Perlin, permettant de créer un nuage de points aléatoires et cohérents entre eux. Puis les couleurs sont appliquées selon la valeur retournée par le bruit, et sont considérées comme la hauteur.<br/><br/>&emsp;Les labyrinthes sont générés de façon à ce que le chemin couvre toute la surface disponible. Ce seront des labyrinthes maniéristes, c'est à dire que d'un point à un autre il n'existe qu'un seul et unique chemin.</div>,
        tech: {
            language: 'C#',
            framework: 'WinForm'
        },
        links: [
            (<div>Lien de téléchargement: <a href={genMap_file}>ici</a></div>)
        ],
        images: [
            {
                caption: {
                    title: 'Fenêtre d\'acceuil',
                    content: 'Comporte la carte avec plusieurs teintes et les paramètres de génération.'
                },
                image: genMap_genMap
            },
            {
                caption: {
                    title: 'Fenêtre du labyrinthe',
                    content: 'La génération se fait avec la touche entrer, et cela affiche le labyrinthe'
                },
                image: genMap_genLaby
            }
        ]
    },
    { // Planing
        id: 6,
        name: 'Planing Kem One',
        nickname: 'Planing',
        icon: planing,
        category: 'mobile',
        summary: <div>Une application pour tout ceux qui travaille en poste chez Kem One.</div>,
        description: <div>&emsp;Suite à mon travail chez Kem One j'ai remarqué un planing difficile à connaître (un cycle de 35 jours). J'ai donc programmé cette application pour connaître son poste en fonction de l'équipe et du jour. Cette application n'est plus utilisée, la seconde version l'a remplacée</div>,
        tech: {
            language: 'C#',
            framework: 'Xamarin'
        },
        links: [],
        images: [
            {
                image: planing1
            },
            {
                image: planing3
            },
            {
                image: planing2
            }
        ]
    },
    { // Dungeon
        id: 8,
        name: 'Donjon Procédural',
        nickname: 'Dungeon',
        icon: dungeon,
        category: 'game',
        summary: <div>C'est un générateur de donjon procédural, applicable à un projet Unity.</div>,
        description: <div>&emsp;C'est un générateur de donjon procédural, applicable à un projet Unity. Les donjons sont générés aléatoirement mais en suivant des règles pour garder une cohérence.<br/><br/>&emsp;Le générateur utilise des pièces préfabriquées qu'il assemble de façon cohérente jusqu'à la sortie de l'étage. Il génère aussi quelque petits chemins autres que le principal.</div>,
        tech: {
            language: 'C#',
            framework: 'Unity'
        },
        links: [
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt GIT <a href="https://github.com/LRojon/Dungeon_Generator">ICI</a></div>)
        ],
        images: [
            {
                caption: {
                    title: 'Interface',
                    content: 'Interface graphique de Unity.'
                },
                image: dungeon_interface
            },
            {
                caption: {
                    title: 'Carte',
                    content: 'Exemple d\'une génération.'
                },
                image: dungeon_map
            },
            {
                caption: {
                    title: 'Le jeu',
                    content: 'Résultat du test en jeu.'
                },
                image: dungeon_inGame
            }
        ]
    },
    { // Albion
        id: 7,
        name: 'Outils économique - Albion online',
        nickname: 'Albion',
        icon: albion,
        category: 'mobile',
        summary: <div>Albion online est un jeu en ligne où l'économie est développée et laissée au joueurs.Dans ce cadre cet outil montrant les prix les plus bas de chaque marché est utile.</div>,
        description: <div>&emsp;Albion online est un jeu en ligne où l'économie est développée et laissée au joueurs. Dans ce cadre cet outil montrant les prix les plus bas de chaque marché est utile.<br/><br/>&emsp;Dans ce jeu où l'économie est un champ de bataille, j'ai développé cette application pour mon groupe de joueur, pour avoir un avantage non négligeable.<br/><br/>&emsp;Une fois la recherche d'un produit spécifiques effectuée, une liste de résultats apparait. Chaque ligne contient le nom de la ville où se trouve la ressource, une image du produit, son prix le plus bas, et la date de la mise en vente.</div>,
        tech: {
            language: 'C#',
            framework: 'Xamarin'
        },
        links: [],
        images: [
            {
                caption: {
                    title: 'Page de recherche',
                    content: 'Comporte tous les critères de recherche possible.'
                },
                image: albion_search
            },
            {
                caption: {
                    title: 'Résultat',
                    content: 'Résultat pour un type de ressource sur un seul marché.'
                },
                image: albion_result1
            },
            {
                caption: {
                    title: 'Comparatif',
                    content: 'Résultat comparatif d\'une même ressource sur plusieurs marchés.'
                },
                image: albion_result2
            }
        ]
    },
    { // Bestiaire
        id: 3,
        name: 'Bestiaire Chroniques oubliées',
        nickname: 'Bestiaire',
        icon: bestiary,
        category: 'web',
        summary: <div>Ce site sert au joueur du jeu de rôle Chroniques oubliées. C'est un receuil de tous les monstres disponibles dans le livre de base.</div>,
        description: <div>&emsp;Ce site sert au joueur du jeu de rôle Chroniques oubliées. C'est un receuil de tous les monstres disponibles dans le livre de base. Cependant, ce receuil est communautaire. Tout le monde peut ajouter et pourra modifier des monstres à sa convenance.<br/><br/>&emsp;Ce bestiaire permet de voir les monstres ainsi que leurs capacités et de leurs compétences. Il a été fait de sorte à ce que tout soit connecté, ainsi on change facilement d'un monstre au descriptif de sa compétence.</div>,
        tech: {
            language: 'HTML, CSS, JS',
            framework: 'JQuery, Bootstrap 4, DHTMLX5'
        },
        links: [
            (<div>Le site est <a href="http://bestiaire.alwaysdata.net/">ici</a></div>),
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépot GIT <a href="https://github.com/LRojon/Bestiary">ici</a></div>)
        ],
        images: [
            {
                caption: {
                    title: 'Onglet d\'acceuil',
                    content: 'Affiche la liste des monstres existants, et permet d\'afficher les details pour chaque monstre.'
                },
                image: bestiary_home
            },
            {
                caption: {
                    title: 'Récompense',
                    content: 'Génère des récompenses selon le monstre abattu.'
                },
                image: bestiary_loot
            },
            {
                caption: {
                    title: 'Onglet des compétences',
                    content: 'Affiche la liste des compétences organisées par voie puis par rang.<br/>Affiche l\'effet et les utilisateurs de la compétence. '
                },
                image: bestiary_skill
            },
            {
                caption: {
                    title: 'Onglet des capacités',
                    content: 'Affiche la liste des capacités ainsi que leurs effets et leurs utilisateurs.'
                },
                image: bestiary_abilities
            },
            {
                caption: {
                    title: 'Fenêtre de création',
                    content: 'Permet de créer ses propres monstres.'
                },
                image: bestiary_new
            },
        ]
    },
    { // Cardmaker
        id: 4,
        name: 'Cardmaker',
        nickname: 'Cardmaker',
        icon: cardmaker,
        category: 'web',
        summary: <div>Je monte un projet avec un ami visant à mettre en place un jeu de cartes. J'ai conçu ce site pour pouvoir créer de nouvelles cartes plus facilement</div>,
        description: <div>&emsp;Je monte un projet avec un ami visant à mettre en place un jeu de cartes. J'ai conçu ce site pour pouvoir créer de nouvelles cartes plus facilement, avec une approche communautaire à terme. En effet, toute personne pourra ajouter la carte qu'il souhaite, puis elle sera examinée par un jury, avant d'être intégrée ou non au jeu.<br/><br/>&emsp;Il est posible de filtrer la recherche par le nom via la barre de recherche, mais aussi via une liste déroulante. J'ai ajouté une fonction de tri, accessible par une liste déroulante elle aussi.<br/><br/>&emsp; Le site prend ses données sur API en Python.</div>,
        tech: {
            language: 'HTML, CSS, JS, Python',
            framework: 'Bootstrap 4, Flask'
        },
        links: [
            (<div>Lien vers le site <a href="https://card.lrojon.fr/">ici</a></div>),
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt du site <a href="https://github.com/LRojon/RPG-GO-Maker">ici</a></div>),
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt de l'API <a href="https://github.com/LRojon/apiPython">ici</a></div>)
        ],
        images: [
            {
                caption: {
                    title: 'Page d\'acceuil',
                    content: 'Comporte toutes les cartes, triées en fonction de leur id.'
                },
                image: cardmaker_home
            },
            {
                caption: {
                    title: 'Ajout',
                    content: 'Formulaire pour ajouter une carte.'
                },
                image: cardmaker_add
            },
            {
                caption: {
                    title: 'Modification',
                    content: 'Formulaire pour modifier une carte existante.'
                },
                image: cardmaker_modify
            },
        ]
    },
    { // WorldGen
        id: 2,
        name: 'Générateur de monde',
        nickname: 'WorldGen',
        icon: worldGen,
        category: 'software',
        summary: <div>Un projet hérité du Générateur de cartes. En plus de générer une carte, le logiciel génère des pays, des villes, ainsi qu'un panthéon de dieux.</div>,
        description: <div>&emsp;Un projet hérité du Générateur de cartes. En plus de générer une carte, le logiciel génère des pays, des villes, ainsi qu'un panthéon de dieux. Un outil simple d'utilisation, à destination des maîtres de jeu lors de séances de jeu de rôle.<br/><br/>&emsp;Grâce à ce logiciel, on peut voir les données citées ci dessus, mais aussi sauvegarder ces données pour les reprendre plus tard.</div>,
        tech: {
            language: 'C#',
            framework: 'WPF'
        },
        links: [
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt du projet <a href="https://github.com/LRojon/WorldGen">ici</a></div>),
        ],
        images: [
            {
                caption: {
                    title: 'Acceuil',
                    content: 'Affiche la carte du monde, avec les royaumes en surbrillance.'
                },
                image: worldGen_home
            },
            {
                caption: {
                    title: 'Royaume',
                    content: 'Écran d\'information par royaume. Et de leurs territoires.'
                },
                image: worldGen_kingdom
            },
            {
                caption: {
                    title: 'Ville',
                    content: 'Écran d\'information par ville.'
                },
                image: worldGen_City
            },
            {
                caption: {
                    title: 'Dieux',
                    content: 'Écran d\'information par dieu, avec leur zone d\'influence.'
                },
                image: worldGen_Gods
            },
        ]
    },
    { // FnY
        id: 5,
        name: 'Fumetsu no Yume',
        nickname: 'FnY',
        icon: fny,
        category: 'web',
        summary:<div>Lors d'un stage, j'ai approché une association de jeux de société qui ne possédaient pas de site, j'en ai donc créé un.</div>,
        description: <div>&emsp;Lors d'un stage, j'ai approché une association de jeux de société qui ne possédaient pas de site, j'en ai donc créé un.<br/><br/>&emsp;Le site permet aux visiteurs de voir les articles postés par le bureau de l'association, ainsi que les évènements, et le réglement. Il permet aussi de faire une préinscription. Il contient un panneau de contrôle côté administrateur. Ce dernier permet de voir les tâches à faire, les préinscripitons, la liste des membres, les réunions à venir et passées ainsi que le compte rendu de ces réunions. Le dernier onglet est réservé aux statistiques du site.<br/><br/>&emsp; Suite à l'épisode pandémiques du COVID-19, l'association a du restreindre ses dépense, le site n'est plus en ligne.</div>,
        tech: {
            language: 'HTML, CSS, PHP, MySQL',
            framework: 'Symfony 4, Bootstrap 4'
        },
        links: [],
        images: [
            {
                caption: {
                    title: 'Page d\'acceuil'
                },
                image: fny_home
            },
            {
                caption: {
                    title: 'Ajout d\'article',
                },
                image: fny_newA
            },
            {
                caption: {
                    title: 'Ajout d\'évènements'
                },
                image: fny_newE
            },
            {
                caption: {
                    title: 'Préinscription'
                },
                image: fny_inscription
            },
            {
                caption: {
                    title: 'Tâches du bureau'
                },
                image: fny_task
            },
            {
                caption: {
                    title: 'Compte rendu de réunion'
                },
                image: fny_report
            },
        ]
    },
    { // Planing2
        id: 9,
        name: 'Planing V2',
        nickname: 'Planing2',
        icon: planingV2,
        category: 'mobile',
        summary: <div>Cette application est la seconde version de l'application Planing. Elle permet à ceux qui sont en poste chez Kem One, d'avoir leur planing à portée de main.</div>,
        description: <div>&emsp;Cette application est la seconde version de l'application Planing. Elle permet à ceux qui sont en poste chez Kem One, d'avoir leur planing à portée de main.<br/><br/>&emsp;Le planing mis en place chez Kem One est un cycle de 35 jours, il n'est donc pas stable (un jour ne sera pas équivalent à un poste), c'est pour cela que j'ai programmé la première version de l'application. La seconde version existe dans le but d'optimiser.<br/><br/>&emsp;Grâce à React Native l'application peut maintenant être disponible sous android et iOS. J'ai choisi un theme sombre dans un soucis d'économie de batterie, en effet les écrans OLED étant de plus en plus présent, les thèmes sombre sont de plus en plus important.<br/><br/>&emsp;En lancant l'application vous tomber sur la dernière équipe séléctionée, et sur la date du jour, mais rien ne vous empêche de changer l'équipe ou de jour pour connaitre un poste dans le futur, utile pour s'organiser.</div>,
        bigDescription: true,
        tech: {
            language: 'JavaScript, JSX',
            framework: 'React Native'
        },
        links: [],
        images: [
            {
                image: planingV2_5_10
            },
            {
                image: planingV2_3_10
            },
            {
                image: planingV2_3_23
            },
        ]
    },
    { // GenshinOverview
        id: 10,
        name: 'Genshin Overview',
        nickname: 'GenshinOverview',
        icon: genshinOverview,
        category: 'mobile',
        summary: <div>Cette application a pour but d'aider les joueurs de Genshin Impact, en proposant une base de données, et la possibilité de voir ces statistiques.</div>,
        description: <div>&emsp;Cette application a pour but d'aider les joueurs de Genshin Impact, en proposant une base de données, et la possibilité de voir ces statistiques.<br/><br/>&emsp;Elle dispose de deux parties, navigable via un menu latéral.<br/>La première est déstiné à regarder les personnages que l'on possède, ainsi que nos statistiques en exploration comme dans la partie challenge. La seconde, elle, montre tout les personnages, armes, artéfacts, et éléments disponible dans le jeu, ainsi que les ressources disponibles pour le jour actuel.<br/>Le but étant de lié les deux parties pour aider au mieux les joueurs. Par exemple si un joueur veut optimiser un de ses personnages, l'application cherche dans les données tiré d'une de mes API en Python, ainsi qu'une autre de mes API en JavaScript, pour montrer au joueur les ressources nécessaires, et où elles se trouvent.<br/><br/>&emsp;L'application ne verra malheureusement pas le jour. Après un mois et demi à travailler sur mon projet, la maison mère de Genshin Impact, Mihoyo, ayant sortie leur propre application, il m'ai devenu impossible de publier, la mienne.</div>,
        bigDescription: true,
        tech: {
            language: 'JavaScript, JSX, Python, MongoDB',
            framework: 'React Native, Flask, Express, Mongoose'
        },
        links: [
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt GIT de l'application <a href="https://github.com/LRojon/GenshinOverview">ICI</a></div>),
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt GIT de l'API Express <a href="https://github.com/LRojon/APIGenshinDB">ICI</a></div>)
        ],
        images: [
            {
                caption : {
                    title: 'Menu latéral',
                },
                image: genshinOverview_menu
            },
            {
                caption : {
                    title: 'Statistique d\'exploration',
                },
                image: genshinOverview_userInfo
            },
            {
                caption : {
                    title: 'Personnages',
                    content: 'Personnages de l\'utilisateur'
                },
                image: genshinOverview_pCharacter
            },
            {
                caption : {
                    title: 'Détail de personnage',
                    content: 'Détails d\'un personnage de l\'utilisateur'
                },
                image: genshinOverview_pCharacterDetails
            },
            {
                caption : {
                    title: 'Statistiques',
                    content: 'Statistiques par saison'
                },
                image: genshinOverview_pSpiral
            },
            {
                caption : {
                    title: 'Ressources journalières disponible',
                },
                image: genshinOverview_dDaily
            },
            {
                caption : {
                    title: 'Personnages',
                    content: 'Tout les personnages du jeu'
                },
                image: genshinOverview_dCharacter
            },
            {
                caption : {
                    title: 'Détails de personnage',
                    content: 'Détails disponible pour un personnage'
                },
                image: genshinOverview_dCharacterDetails
            },
            {
                caption : {
                    title: 'Armes',
                    content: 'Toutes les armes du jeu'
                },
                image: genshinOverview_dWeapon
            },
            {
                caption : {
                    title: 'Détails d\'arme',
                    content: 'Détails disponible pour une arme'
                },
                image: genshinOverview_dWeaponDetails
            },
            {
                caption : {
                    title: 'Artéfacts',
                    content: 'Tout les artéfacts disponible en jeu'
                },
                image: genshinOverview_dArtifact
            },
            {
                caption : {
                    title: 'Détails d\'artéfact',
                    content: 'Tous les détails pour un set d\'artéfacts'
                },
                image: genshinOverview_dArtifactDetails
            },
            {
                caption : {
                    title: 'Éléments',
                    content: 'Les 7 éléments disponible'
                },
                image: genshinOverview_dElement
            },
            {
                caption : {
                    title: 'Détails d\'élément',
                    content: 'Les détails lié à un élément, et à ses réactions'
                },
                image: genshinOverview_dElementDetails
            },
            {
                caption : {
                    title: 'Filtre',
                    content: 'Chaque section possède son filtre de recherche'
                },
                image: genshinOverview_dFilter
            },
        ]
    },
    { // VoronoiWallpaper
        id: 11,
        name: 'Générateur de fond d\'écran',
        nickname: 'VoronoiWallpaper',
        icon: voronoiWallpaper,
        category: 'web',
        summary: <div>Lorsque j'apprenais à utiliser un diagram de voronoi pour un autre projet, j'ai colorisé les cellules, ce qui m'a donné l'idée de ce projet.</div>,
        description: <div>&emsp;Lorsque j'apprenais à utiliser un diagram de voronoi pour un autre projet, j'ai colorisé les cellules, ce qui m'a donné l'idée de ce projet.<br/><br/>&emsp;Ce site vous permet de configurer une image, de sa taille, à sa couleur horizontale, et verticale, tout en passant par le niveau de détails.<br/>En faisant mes recherche sur les diagrammes de voronoi, je me suis aperçu que je pouvait faire des dégradé avec une apparence de vitraille, j'ai alors décidé de pousser le concepte plus loin.</div>,
        tech: {
            language: 'JavaScript, CSS',
            framework: 'React'
        },
        links: [
            (<div>Lien vers le site <a href="https://wallpaper.lrojon.fr">ICI</a></div>),
            (<div><FontAwesomeIcon icon={['fab', 'github']}/>&emsp;Lien vers le dépôt GIT  <a href="https://github.com/LRojon/Voronoi-Wallpaper">ICI</a></div>),
        ],
        images: [
            {
                caption : {
                    title: 'Acceuil',
                },
                image: voronoiWallpaper_home
            },
            {
                caption : {
                    title: 'Menu latéral',
                },
                image: voronoiWallpaper_menu
            },
            {
                caption : {
                    title: 'Changement de couleur',
                },
                image: voronoiWallpaper_color
            },
            {
                caption : {
                    title: 'Mode dégradé',
                },
                image: voronoiWallpaper_gradient
            },
            {
                caption : {
                    title: 'Mode inversé',
                },
                image: voronoiWallpaper_inverted
            },
            {
                caption : {
                    title: 'Mode dégradé inversé',
                },
                image: voronoiWallpaper_gradient_inverted
            },
        ]
    }
]

export default projects
