import React, { useState } from 'react'
import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import '../Styles/Project.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactModal from 'react-modal'

const Project = ({ project, setCurrentProject }) => {

    const [isOpen, setIsOpen] = useState(false)

    ReactModal.defaultStyles.overlay.backgroundColor = '#0008'

    return (
        <div style={{flex: 1, alignContent: 'center', justifyContent: 'center'}} id={`project-${project.id}`} >
            <button className='projectsButton' onClick={() => setCurrentProject('projects')} ><FontAwesomeIcon icon='lightbulb'/> Projects </button>
            <div className='info' style={{marginBottom: 15}} >
                <h2>{project.name} </h2>
                <div>
                    <h3>Résumé</h3>
                    <span>{project.summary} <button onClick={() => setIsOpen(true)} >voir plus...</button></span>
                </div>
                <div>
                    <h3>Technologie</h3>
                    <span>Le projet est fait en {project.tech.language}, avec le{project.tech.framework.split(',').length > 1 ? 's' : null} framework {project.tech.framework}</span>
                </div>
            </div>
            <Carousel
                plugins={[
                    {
                        resolve: autoplayPlugin,
                        options: {
                            interval: 4500,
                        }
                    },
                    'infinite'
                ]}
                animationSpeed={1500}
            >
                {
                    project.images.map((image, index) => {
                        return (
                            <div className={project.category === 'mobile' ? 'mobile-image' : 'image'}>
                                <img src={image.image} alt={image.caption ? image.caption.title : 'Démonstration'} />
                                {
                                    image.caption ?
                                        (<span>
                                            <h4><strong>{image.caption.title}</strong></h4>
                                            { image.caption.content ? (<p>{image.caption.content}</p>) : null }
                                        </span>)
                                    : null
                                }
                            </div>
                        )
                    })
                }
            </Carousel>
            <ReactModal 
                className='modal'
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
            >
                <div className='nav'>
                    <h3>{project.name}</h3>
                    <button onClick={() => setIsOpen(false)} >
                        <FontAwesomeIcon style={{fontSize: '3em', color: 'white'}} icon='times'/>
                    </button>
                </div>
                <div className='info' >
                    <h3>Résumé</h3>
                    <span style={project.bigDescription ? {fontSize: 12} : null}>{project.description}</span>
                </div>
                <div className='tech' >
                    <h3>Technologie</h3>
                    <span style={project.bigDescription ? {fontSize: 12} : null}>Le projet est fait en {project.tech.language}, avec le{project.tech.framework.split(',').length > 1 ? 's' : null} framework {project.tech.framework}</span>
                </div>
                <div className='tech' >
                    <h3>Liens</h3>
                    <span style={project.bigDescription ? {fontSize: 12} : null}>
                        {
                            project.links.map((elem, index) => {
                                return elem
                            })
                        }
                    </span>
                </div>
            </ReactModal>
        </div>
    )
}

export default Project
