import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Desktop from './Desktop/Component/Desktop'
import Mobile from './Mobile/Component/Mobile'
import Global from './Helpers/global'

const App = (props) => {
    let isDektop = useMediaQuery({ query: '(min-width: 1224px)' })
    let isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    Global.platform = isDektop ? 'Desktop' : (isMobile ? 'Mobile' : '')
    Global.orientation = useMediaQuery({ query: '(orientation: portrait)' }) ? 'portrait' : 'landscape'

    return (
        <div className='App'>
            {Global.platform === 'Desktop' && <Desktop />}
            {Global.platform === 'Mobile' && <Mobile />}
        </div>
    )
}

export default App;
