import React, { useState } from 'react'
import Global from '../../Helpers/global'
import Home from './Home';
import Menu from './Menu';
import Experience from './Experience'
import Projects from './Projects'
import Project from './Project'
import Contact from './Contact'

import '../Styles/Mobile.css'


const Mobile = () => {

    const [page, updatePage] = useState('Home')

    let displayPage = () => {
        switch(page)
        {
            case 'Experience':
                return (<Experience updatePage={updatePage} />)
            case 'Projects':
                return (<Projects />)
            case 'Project':
                return (<Project />)
            case 'Contact':
                return (<Contact />)
            default:
                return (<Home page={page} updatePage={updatePage} />)
        }
    }

    if(Global.orientation === 'landscape' && page !== 'Contact') {
        return (
            <div className="coming-soon">
                <p>The landscape mode coming soon. Be patient.</p>
            </div>
        )
    }
    return (
        <div className='mainContainer'>
            <Menu page={page} updatePage={updatePage} />
            {
                displayPage()
            }
        </div>
    )
}

export default Mobile
