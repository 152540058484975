import React from 'react'
import Accordion from './Helpers/Accordion'
import AccordionItem from './Helpers/AccordionItem'
import Collapse from '../../Helpers/Collapse'

import '../Styles/Experience.css'
import '../Styles/Accordion.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Item = ({title, years, description, children}) => {
    return (
        <div className='item'>
            <h3><strong>{years}</strong> <u>{title}</u></h3>
            <div>
                &emsp;{description}<br/>
                {children}
            </div>
        </div>
    )
}

const Experience = ({ updatePage }) => {
    return (
        <div className='mainContainer'>
            <div className='experienceNav' >
                <button onClick={() => updatePage('Projects')} >
                    <FontAwesomeIcon icon='lightbulb'/> Projets
                </button>
            </div>
            <div className='experience' >
                <Accordion atomic={true}>
                    <AccordionItem title='Emplois'>
                        <div className='section'>
                            <Item title='Orange' years='2019 - 2020' description='Développeur PHP' >
                                <Collapse collapsed={true} title='Tâches effectuées' >
                                        <ul>
                                            <li>Fix de bug d'un ERP.</li>
                                            <li>Comparaison de solution SGBD pour une migration de la base de données de l'ERP.</li>
                                            <li>Migration de la base de données de l'ERP.</li>
                                            <li>Mise en place d'un workflow GIT.</li>
                                            <li>Fait en PHP 5, HTML, CSS, JS. Avec CodeIgniter, DHTMLX, et MariaDB.</li>
                                            <li>Puis, fait en PHP 7, HTML, CSS, JS. Avec Laravel, DHTMLX, et PostgreSQL.</li>
                                        </ul>
                                </Collapse>
                            </Item>
                            <Item title='Kem One' years='2017 et 2018' description='Opérateur services généreaux' >
                                <Collapse collapsed={true} title='Tâches effectuées' >
                                        <ul>
                                            <li>Travail d'été en poste 5x8.</li>
                                            <li>Nettoyage des machines de production.</li>
                                            <li>Dosage des ingrédients.</li>
                                        </ul>
                                </Collapse>
                            </Item>
                        </div>
                    </AccordionItem>

                    <AccordionItem title='Stages'>
                        <div className='section'>
                            <Item title='Association Fumetsu no Yume' years='2019' description='Développeur PHP'>
                                <Collapse collapsed={true} title='Tâches effectuées' >
                                    <ul>
                                        <li>Conception et développement d'un site vitrine et d'une interface administrateur.</li>
                                        <li>Mise en production de ces derniers.</li>
                                        <li>Maintenance en bénévolat jusqu'au 27 juillet 2021.</li>
                                        <li>Fait en PHP. Avec Symfony 4, et Bootstrap 4.</li>
                                    </ul>
                                </Collapse>
                            </Item>
                            <Item title='Carsat Rhône Alpes' years='2018' description='Développeur C#'>
                                <p style={{backgroundColor: '#0004', borderRadius: 15, padding: 5}}>Développement d'une application web de gestion de formation.<br/>Fait en C#, ASP.Net, EntityFramework.</p>
                            </Item>
                        </div>
                    </AccordionItem>

                    <AccordionItem title='Études'>
                        <div className='section'>
                            <Item title="Concepteur Développeur d'Application Numérique, formation suivie" years='2019 - 2020' description='IPI Lyon, Lyon' />
                            <Item title="BTS Services Informatiques aux Organisations Option SLAM" years='2017 - 2019' description='Lycée Jacques Brel, Vénissieux' />
                            <Item title='Licence Maths-Info' years='2015 - 2017' description='Université Claude Bernard, Villeurbanne' />
                            <Item title="Baccalauréat S-SI" years='2015' description='Lycée Léonard de Vinci, Villefontaine' />
                        </div>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}

export default Experience
