import React, { useState } from 'react'
import Home from './Home'
import Menu from './Menu'

import '../Styles/Desktop.css'
import Experience from './Experience'
import Projects from './Projects'
import Contact from '../../Mobile/Component/Contact'

const Desktop = () => {

    const [page, updatePage] = useState('Home')

    const displayPage = () => {
        switch(page)
        {
            case 'Experience':
                return <Experience />
            case 'Projects':
                return <Projects />
            case 'Contact':
                return <Contact />
            default:
                return <Home updatePage={updatePage} />
        }
    }

    return (
        <div className="mainContainer" >
            <Menu page={page} updatePage={updatePage} />
            <div className='content' >
                {
                    displayPage()
                }
            </div>
        </div>
    )
}

export default Desktop
